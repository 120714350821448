.altForm {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
    border-bottom: 1px solid #0377ba;
    .form-row {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        .form-label {
            width: 35%;
            display: flex;
            justify-content: flex-end;
            font-size: 15px;
            height: 100%;
            margin-bottom: 15px;
            .label-req {
                color: #bc3232;
                margin-right: 5px;

            }
        }
        .form-value {
            width: 57%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            input {
                padding-left: 9px;
                margin: 0 0 0 4px;
                font-size: 15px;
                height: 40px;
                width: 70%;
                border: 1px solid rgba(204,204,204,0.9);
                background: rgba(255,255,255,0.9);
                box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
                transition: border linear .2s,box-shadow linear .2s;
                border-radius: 3px;
                &.focus-active {
                    border: 1px solid #ce4635;
                }
            }
            .form-text {
                width: 70%;
                font-size: .74em;
                line-height: 20px;
                padding-left: 9px;
                margin-top: 4px;
            }
            .form-error {
                color: #ce4635;
                font-size: 13px;
            }
        }
    }
}
.account-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    button {
        font-size: 16px;
        padding: 10px 24px;
        cursor: pointer;
        color: #ffffff;
        border-radius: 5px;
        box-shadow: none;
        text-shadow: none;
        text-decoration: none;
        border: none;
        margin-top: 20px;
    }
    .cancel {
        background: #0878A4;
        order: -1;
        &:hover {
            background: #1f6377;
        }
    }
    .next {
        background: #37803d;
        order: 1;
        &:hover {
            background: #007e74;
        }
    }
}