.account-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    .account-form {
        width: 65%;
        position: relative;
        padding: 10px;
        .error {
            background: #facbcb;
            margin: 0 0 20px;
            text-align: center;
            width: 100%;
            border-radius: 2px;
        }
        .error-container {
            padding: 33px;
            text-align: left;
            .error-text {
                font-size: 15px;
                color: #b60c0c;
                    ul {
                        list-style: none;
                    }
                    ul li::before {
                        content: "\2022";
                        font-weight: bold;
                        display: inline-block; 
                        width: 1em;
                        margin-left: -1em;
                    }
            }
        }
        @media screen and (max-width: 991px) {
            width: 100%;
            padding: unset;
        }
            .label-text {
            margin-top: 30px;
            .label-req {
                color: #bc3232;
                margin-right: 5px;
            }
        }
        .account-links {
            width: 100%;
            display: flex;
            position: relative;
            .link-myaccount {
                width: 50%;
                text-align: center;
                border-bottom: 1px solid #C0C0C0;
                @media screen and (max-width: 575px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .my-link {
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.4px;
                    color: #525252;
                    @media screen and (max-width: 575px) {
                       margin: 10px;
                    }
                }
            }
            .link-myaccount:hover {
                .my-link {
                    color: #0878b5;
                }
            }
            .link-alternate {
                width: 50%;
                text-align: center;
                position: relative;
                @media screen and (max-width: 575px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .alt-link {
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.4px;
                    color: #0878b5;
                    @media screen and (max-width: 575px) {
                        margin: 10px;
                    }
                }
            }
            .link-alternate::after {
                content: '';
                width: 100%;
                position: absolute;
                bottom: -2.5px;
                border-bottom: solid 5px #0878b5;
                left: 0;
            }
        }
        .account-buttons {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 991px) {
                margin: 10px 0 20px 0;
            }
            button {
                font-size: 16px;
                padding: 10px 24px;
                cursor: pointer;
                color: #ffffff;
                border-radius: 5px;
                box-shadow: none;
                text-shadow: none;
                text-decoration: none;
                border: none;
                margin-top: 20px;
                @media screen and (max-width: 991px) {
                    margin-top: 0;
                }
            }
            .cancel {
                background: #0878A4;
                &:hover {
                    background: #1f6377;
                }
            }
            .next {
                background: #37803d;
                &:hover {
                    background: #007e74;
                }
            }
        }
    }
    .account-image {
        width: 35%;
        position: relative;
        padding: 30px 0;
        border-bottom: solid 1px #a9d0e5;
        @media screen and (max-width: 991px) {
            display: none;
        }
        .account-title {
            font-size: 16px;
            font-weight: bold;
            color: #525252;
            margin: 0;
        }
        .account-text {
            font-size: 12px;
            margin: 0;
        }
        .account-img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    .account-image::before {
        content: '';
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #a9d0e5;
        position: absolute;
        top: 0;
    }
}

#userScreensDiv {
    .gigya-screen.portrait {
        @media screen and (max-width: 575px) {
            width: 100%;
            padding: 0;
        }
    }
    .gigya-screen-caption {
        display: none;
        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
    .gigya-screen.landscape {
        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
    .gigya-layout-row {
        .with-divider {
            display: none;
        }
        .gigya-error-msg-active {
            background: #facbcb;
            color: #b60c0c;
            padding: 20px;
            margin-bottom: 20px;
            li {
                width: 90%;
                color: #b60c0c;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
    .with-site-login {
        display: none;
    }
    .gigya-clear {
        display: none;
    }
}