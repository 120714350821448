.account-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    .account-form {
        width: 65%;
        position: relative;
        padding: 10px;
            .label-text {
            margin-top: 30px;
            .label-req {
                color: #bc3232;
                margin-right: 5px;
            }
        }
        .account-links {
            width: 100%;
            display: flex;
            position: relative;
            .link-myaccount {
                width: 50%;
                text-align: center;
                border-bottom: 1px solid #C0C0C0;
                text-decoration: none;
                .my-link {
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.4px;
                    color: #525252;
                }
            }:focus{
                outline: none;
            }
            .link-myaccount:hover {
                .my-link {
                    color: #0878b5;
                }
            }
            .link-alternate {
                width: 50%;
                text-align: center;
                position: relative;
                .alt-link {
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.4px;
                    color: #0878b5;
                }
            }
            .link-alternate::after {
                content: '';
                width: 100%;
                position: absolute;
                bottom: -2.5px;
                border-bottom: solid 5px #0878b5;
                left: 0;
            }
        }
    }
    .account-image {
        width: 35%;
        position: relative;
        padding: 30px 0;
        border-bottom: solid 1px #a9d0e5;
        .account-title {
            font-size: 16px;
            font-weight: bold;
            color: #525252;
            margin: 0;
        }
        .account-text {
            font-size: 12px;
            margin: 0;
        }
        .account-img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    .account-image::before {
        content: '';
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #a9d0e5;
        position: absolute;
        top: 0;
    }
}