.navbar {
    width: 100%;
    display: flex;
    position: relative;
    padding: 23px 0;
    .navbar-container {
        margin: 0 8%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .navbar-logo {
            width: fit-content;
            height: 100%;
            display: flex;
            margin: 0;
            justify-content: flex-start;
            img {
                width: auto;
                height: 100%;
                object-fit: contain;
            }
        }
        .account-logout {
            display: flex;
            text-decoration: none;
            border: none;
            border-radius: 5px;
            box-shadow: none;
            color: #fff;
            cursor: pointer;
            font-size: 16px;
            padding: 10px 24px;
            text-decoration: none;
            text-shadow: none;
            background: #0878a4;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &::after {
                content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAJCAYAAAARml2dAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGNzdGMTE3NDA3MjA2ODExOEE2RDg5RkFGRjQwQjQ5RiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpCQUQ3Q0M3NEUxMEYxMUUyOENFMzg0QzcyN0QxOTJCNiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpCQUQ3Q0M3M0UxMEYxMUUyOENFMzg0QzcyN0QxOTJCNiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDk4MDExNzQwNzIwNjgxMThDMTRGNkE3Q0Q5OEZFRTQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Rjc3RjExNzQwNzIwNjgxMThBNkQ4OUZBRkY0MEI0OUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4sZzxuAAAAOklEQVR42mL4////of8QAKIZYJgRJMKAAIwwBhMQH4ayDzMgA2TtyMYyoprE8B/ZKGSAMJZ6rgIIMABVHk3lNrvhXQAAAABJRU5ErkJggg") !important;
                background-repeat: no-repeat !important;
                text-shadow: 0px 0px #dad8d8 !important;
                margin-left: 5px;
            }
        } img {
            margin-left: 5px;
            object-fit: contain;
        }
        .account-logout:hover {
            background: #1f6377;
        }
    }
}
.navbar-container::after {
    background: #0878b5;
    content: "";
    display: block;
    height: 9px;
    width: 100%;
    z-index: 1;
    bottom: 0;
    position: absolute;
    left: 0;
    -webkit-box-shadow: 0 3px 4px -3px black;
    -moz-box-shadow: 0 3px 4px -3px black;
    box-shadow: 0 3px 4px -3px black;
}

#popup-root {
    // .popup-overlay {
    //     background: #00000028;
    //     filter: blur(1.5rem);
    // }
    [data-popup='tooltip'].popup-overlay {
        background: #00000028;
        filter: blur(1.5rem);
      }

    .popup-arrow {
        display: none;
    }
    [role='tooltip'].popup-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        background: rgb(255, 255, 255);
        padding: 25px !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        text-align: center;
      }
      [role='dialog'].popup-content {
        width: 325px !important;
        padding: 20px;
        text-align: center;
      }
      @media screen and (max-width: 575px) {
        .popup-content {
            width: 60% !important;
        }
      }
}