.logout-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
}

.loading-gif {
    object-fit: contain;
    height: 50px;
}

.popupText {
    margin: 25px 0 0 0;
    font-size: 15px;
    font-weight: bold !important;
    color: #6a6a6a;
}

.Okbutton {
    background-color: #0878b5;
    width: 18%;
    height: 47px;
    padding: 6px 12px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    box-shadow: none;
    border: none;
    background-image: none;
    color: #ffffff;
    font-family: 'interstate', serif;
    text-shadow: none;
    text-decoration: none;
    cursor: pointer;
    @media screen and (max-width: 575px) {
            width: 30% !important;
      }
}

.popupOverlay {  
    position: fixed !important;
    top: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
    opacity: 0.0 !important;
    z-index: 10000 !important;
    background-color: #000000 !important;
    display: none !important;
}