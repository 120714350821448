.logout-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}

.loading-gif {
    object-fit: contain;
    height: 50px;
}

.popup-text {
    margin: 25px 0 0 0;
    font-size: 12px;
}
