body {
    font-family: 'Interstate';
}

.hero-container {
    display: block;
    width: 85%;
    margin: 0 auto;
    box-shadow: 0 6px 0.4em rgb(33 33 33 / 7%), -6px 6px 0.4em rgb(33 33 33 / 9%), 10px 6px 0.4em rgb(33 33 33 / 12%);
    padding: 25px 20px 60px 25px;
    @media screen and (max-width: 991px) {
        // width: 100%;
        box-shadow: unset;
        box-shadow: unset;
        display: flex;
        padding: unset;
    }
}

.dsk-spacing {
    @media screen and (max-width: 575px) {
        display: none !important;
    }
}