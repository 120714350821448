.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    min-height: 300px;
    background: #0878b5;
    font-size: 13px;
    line-height: 20px;
    position: relative;
    @media screen and (max-width: 575px) {
        min-height: 320px;
        justify-content: flex-end;
    }
    .footer-bar {
        box-shadow: 0 6px 0.4em rgb(33 33 33 / 7%), -6px 6px 0.4em rgb(33 33 33 / 9%), 10px 6px 0.4em rgb(33 33 33 / 12%);
        border-radius: 0 0 0.5em 0.5em;
        height: 85px;
        top: 0;
        width: 85%;
        padding: 0 25px;
        background-color: #fff;
        position: absolute;
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: 991px) {
            width: 100%;
            box-shadow: unset;
            padding: unset;
            border-radius: unset;
        }
        a {
            display: flex;
            align-items: center;
            width: fit-content;
            margin: 0;
            text-decoration: underline;
            color: #525252;
            cursor: pointer;
        }
    }
    
    .footer-content {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        .footer-text {
            margin: 5px 0 0 0;
            color: #fff;
            @media screen and (max-width: 575px) {
                padding: 10px;
            }
        }:last-child{
            margin-bottom: 5px;
        }
    }
    .footer-image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .footer-logo {
            object-fit: contain;
            height: auto;
        }
        @media screen and (max-width: 767px) {
            .footer-logo {
                object-fit: contain;
                height: auto;
                width: unset;
                max-width: 575px;
            }
        }
        @media screen and (max-width: 575px) {
            .footer-logo {
                object-fit: contain;
                height: auto;
                width: unset;
                max-width: 320px;
            }
        }
    }
}
.footer-bar::before {
    background: url(../images/footerShadow.png) no-repeat center bottom / 83% 5px;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
}