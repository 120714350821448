.hero-container {
    .main-container {
        .detail-data {
            .row-data {
                transition: padding-bottom 300ms ease-out;
                line-height: 48px;
                text-shadow: 0px 0px #dad8d8;
                margin-top: 50px;
                &:before, &:after {
                    display: table;
                    content: "";
                    line-height: 0;
                }
                &:first-child {
                    margin-left: 0;
                }
                &-[class*="span"] {
                    display: block;
                    width: 100%;
                    min-height: 30px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    float: left;
                    margin-left: 1.81818182%;
                    margin-left: 1.76767677%;
                }
                
                input {
                    width: 70%;
                    padding-left: 9px;
                    box-sizing: border-box;
                    border-radius: 3px;
                    font-size: 15px;
                    font-family: interstate;
                    height: 40px;
                    background-color: rgba(255,255,255,0.9);
                    border: 1px solid rgba(204,204,204,0.9);
                    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
                    transition: border linear .2s,box-shadow linear .2s;
                }
                .check-req {
                    display: flex !important;
                    & > div {
                        margin-right: 15px;
                        input[type=checkbox] {
                            width: 25px;
                        }
                    }
                }
                .radio {
                    width: 30%;
                    @media screen and (max-width: 575px) {
                        width: 50%;
                    }
                    label {
                        display: flex;
                        input[type=radio] {
                            width: 26px;
                            display: flex;
                            margin-right: 10px;
                        }
                    }
                }
                .text-label {
                    color: #0878b5;
                    font-size: 18px;
                    font-weight: 300;
                    width: 25%;
                    @media screen and (max-width: 575px) {
                        width: 100%;
                    }
                }
                .row {
                    width: 100%;
                    display: inline-block;
                    div {
                        display: inline-block;
                    }
                }
                .react-datepicker__header{
                    background: #fff;
                }

                .react-datepicker__month-container{
                    box-shadow: 2px -1px #0887c4, -1px 0 5px 0px #216BBC;
                }
                .react-datepicker__current-month {
                    color: #216ba5;
                }
                .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before, .react-datepicker__year-read-view--down-arrow {
                    border-color: #216ba5;
                }
                .react-datepicker__day-names {
                    font-weight: bold;
                }
                .full-width {
                    width: 100%;
                    .date-margin {
                        margin-left: 20.5%;
                        .date-container {
                            display: flex;
                            .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
                                border-bottom-color: #1388c2;
                            }
                            .date-label {
                                width: 23%;
                                margin-left: 5px;
                                text-align: left;
                            }
                            .calendar-icon {
                                background-color: #0878b5;
                                padding: 9px;
                                margin: 0 0 0 6px;
                                cursor: pointer;
                                -webkit-border-radius: 3px;
                                -moz-border-radius: 3px;
                                border-radius: 3px;
                                &:hover {
                                    background-color: #0a244b;
                                }
                            }
                            .date-column-right {
                                display: flex !important;
                            }
                            .img-content {
                                margin-left: -49px;
                                margin-top: 2px;
                            }
                        }
                    }
                }
                .span7 {
                    width: 57.57575758%;
                    @media screen and (max-width: 575px) {
                        width: 100%;
                    }
                }
                .border-separator {
                    border-bottom: 1px solid #0377ba;
                    padding-top: 50px;
                    width: 100%;
                    @media screen and (max-width: 575px) {
                        padding-top: 5px;
                    }
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .text-left {
                    text-align: left;
                }
                .data-container {
                    display: flex !important;
                    @media screen and (max-width: 575px) {
                        width: 100%;
                    }
                    .data-information {
                        display: flex;
                        &>div {
                            margin-right: 15px;
                        }
                    }
                    .account-information {
                        display: grid;
                        line-height: 22px;
                        margin-top: 13px;
                    }
                    .account-control {
                        text-decoration: underline;
                        color: #0878b5;
                        margin-bottom: 10px;
                    }
                    .account-control::before {
                        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAJCAYAAAARml2dAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGNzdGMTE3NDA3MjA2ODExOEE2RDg5RkFGRjQwQjQ5RiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpCQUQ3Q0M3NEUxMEYxMUUyOENFMzg0QzcyN0QxOTJCNiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpCQUQ3Q0M3M0UxMEYxMUUyOENFMzg0QzcyN0QxOTJCNiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDk4MDExNzQwNzIwNjgxMThDMTRGNkE3Q0Q5OEZFRTQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Rjc3RjExNzQwNzIwNjgxMThBNkQ4OUZBRkY0MEI0OUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4sZzxuAAAAOklEQVR42mL4////of8QAKIZYJgRJMKAAIwwBhMQH4ayDzMgA2TtyMYyoprE8B/ZKGSAMJZ6rgIIMABVHk3lNrvhXQAAAABJRU5ErkJggg") !important;
                        background-repeat: no-repeat !important;
                        text-shadow: 0px 0px #dad8d8 !important;
                    }
                    .table-container1 {
                        border-radius: 4px;
                        overflow-y: auto;
                        width: auto;
                        border-bottom: 1px solid #40ade5;
                        width: 80%;
                        @media screen and (max-width: 575px) {
                            width: 100%;
                        }
                        table {
                            display: table;
                            border-collapse: separate;
                            box-sizing: border-box;
                            text-indent: initial;
                            border-spacing: 2px;
                            border-color: grey;
                            tbody {
                                display: table-row-group;
                                vertical-align: middle;
                                border-color: inherit;
                                tr {
                                    display: table-row;
                                    vertical-align: inherit;
                                    border-color: inherit;
                                    th {
                                        background-color: #0878b5;
                                        font-family: inherit;
                                        text-align: left;
                                        color: #ffffff;
                                        height: 40px;
                                        padding-left: 1em;
                                        padding-right: 1em;
                                        font-weight: bold;
                                        border-width: 1px;
                                        border-style: solid;
                                        border-top-color: #2d8ec6;
                                        border-bottom-color: #026096;
                                        border-left-color: #ffffff;
                                        white-space: nowrap;
                                    }
                                    td:first-child {
                                        width: 50% !important;
                                        @media screen and (max-width: 575px) {
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: center;
                                            width: 100% !important;
                                            height: 42px;
                                         }
                                    }
                                    td {
                                        width:50%;
                                        line-height: 20px;
                                        vertical-align: middle;
                                        input[type=radio]{
                                            width: 35px;
                                            margin-right: 20px;
                                            @media screen and (max-width: 575px) {
                                                margin-right: unset;
                                                margin: 5px;
                                              }
                                        }
                                        @media screen and (max-width: 575px) {
                                            li {
                                                white-space: nowrap;
                                            }
                                          }
                                    }
                                }
                            }
                        }
                    }
                    .table-container {
                        overflow-y: auto;
                        border: 1px solid rgba(204, 204, 204, 0.9);
                        border-radius: 4px;
                        padding: 0.5em;
                        width: auto;
                        @media screen and (max-width: 575px) {
                            max-width: 325px;
                        }
                        table {
                            display: table;
                            border-collapse: separate;
                            box-sizing: border-box;
                            text-indent: initial;
                            border-spacing: 2px;
                            border-color: grey;
                            width: 400px;
                            tbody {
                                display: table-row-group;
                                vertical-align: middle;
                                border-color: inherit;
                                tr {
                                    display: table-row;
                                    vertical-align: inherit;
                                    border-color: inherit;
                                    td {
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                    .account-list {
                        display: flex;
                        overflow-y: auto;
                        border: 1px solid rgba(204, 204, 204, 0.9);
                        border-radius: 4px;
                        width: auto;
                        line-height: 20px;
                        font-weight: bold;
                        &>.col {
                           width: 250px;
                        }
                        .col {
                            display: grid;
                        }
                    }
                    .account-list1 {
                        display: flex;
                        overflow-y: auto;
                        border: 1px solid rgba(204, 204, 204, 0.9);
                        border-radius: 4px;
                        width: auto;
                        line-height: 20px;
                        &>.col {
                            width: 250px;
                        }
                        .col {
                            display: grid;
                            div:first-child {
                                font-weight: bold;
                                background-color: #fff;
                                font-family: inherit;
                                text-align: left;
                                color: #525252;
                                height: 40px;
                                padding-left: 1em;
                                padding-right: 1em;
                                font-weight: bold;
                                border-width: 1px;
                                border-style: solid;
                                border: 0;
                                white-space: nowrap;
                            }
                        }
                    }
                }
                .form-indent-label {
                    @media screen and (max-width: 575px) {
                        flex-direction: 100%;
                    }
                    .field-left {
                        width: 20%;
                        text-align: right;
                        @media screen and (max-width: 575px) {
                            width: 100%;
                            text-align: left;
                        }
                    }
                    .field-right {
                        text-align: left;
                        padding-left: 50px;
                        width: 60%;
                        vertical-align: top;
                        @media screen and (max-width: 575px) {
                            width: 100%;
                            padding-left: unset;
                        }
                        .data-information {
                            display: flex;
                            &>div {
                                margin-right: 15px;
                            }
                        }
                        .accounts-table {
                            max-height:10em;
                            border:1px solid rgba(204, 204, 204, 0.9);
                            border-radius:4px;
                            padding: 0.5em;
                            width: 57%;
                            overflow-y: auto;
                            @media screen and (max-width: 575px) {
                                width: 100%;
                            }
                            .accounts-chk-container {
                                display: flex;
                                position: relative;
                                justify-content: left;
                                align-items: center;
                                .accounts-checkbox {
                                    width: 100%;
                                    height: 1.625em;
                                    position: absolute;
                                    cursor: pointer;
                                    opacity: 0;
                                    z-index: 100;
                                }
                                .accounts-checkmark {
                                    width: 1.625em;
                                    height: 1.625em;
                                    position: relative;
                                }
                                .checked {
                                    background: url(../images/FormElements.png) no-repeat -64px -4px;
                                }
                                .unchecked {
                                    background: url(../images/FormElements.png) no-repeat -5px -4px;
                                }
                                .accounts-label {
                                    padding-left: .2em;
                                    cursor: pointer;
                                    padding-top: 0.2em;
                                    line-height: 1.5;
                                    color: #525252;
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                    &.form-indent-input-label {
                        line-height: 75px;
                        @media screen and (max-width: 575px) {
                            line-height: 45px;
                        }
                    }
                }
                a {
                    &.terms-redirection, &.tab-switch {
                        color: #0878b5;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
                button {
                    border: none;
                    border-radius: 5px;
                    box-shadow: none;
                    color: #fff;
                    cursor: pointer;
                    font-size: 16px;
                    margin-top: 20px;
                    padding: 10px 24px;
                    text-decoration: none;
                    text-shadow: none;
                    @media screen and (max-width: 575px) {
                        margin-top: 0 !important;
                        margin-bottom: 20px;
                    }
                    &.submit-loa {
                        background: #37803d;
                        @media screen and (max-width: 575px) {
                            width: 100%;
                        }
                        &:hover {
                            background: #007e74;
                        }
                    }
                }  
            }
        }
    }
}


#hidden-trigger {
    display: none;
}

.error {
    background: #facbcb;
    margin: 0 0 20px;
    text-align: center;
    width: 100%;
    border-radius: 2px;
}
.error-container {
    padding: 33px;
    text-align: left;
    .error-text {
        font-size: 15px;
        color: #b60c0c;
            ul {
                list-style: none;
            }
            ul li::before {
                content: "\2022";
                font-weight: bold;
                display: inline-block; 
                width: 1em;
                margin-left: -1em;
            }
    }
}