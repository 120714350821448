.altForm {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
    border-bottom: 1px solid #0377ba;
    text-shadow: 0px 0px #dad8d8;
    @media screen and (max-width: 575px) {
        margin: 10px;
        padding-bottom: 15px;
    }
    .form-row {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-width: 575px) {
            flex-direction: column;
        }
        .form-label {
            width: 35%;
            display: flex;
            justify-content: flex-end;
            font-size: 15px;
            height: 100%;
            margin-bottom: 15px;
            @media screen and (max-width: 575px) {
                width: 100%;
                justify-content: flex-start;
                margin-bottom: 5px;
            }
            .label-req {
                color: #bc3232;
                margin-right: 5px;

            }
        }
        .form-value {
            width: 57%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            @media screen and (max-width: 575px) {
                width: 100%;
                justify-content: flex-start;
                margin-bottom: 10px;
            }
            input {
                padding-left: 9px;
                margin: 0 0 0 4px;
                font-size: 15px;
                height: 40px;
                width: 70%;
                border: 1px solid rgba(204,204,204,0.9);
                background: rgba(255,255,255,0.9);
                box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
                transition: border linear .2s,box-shadow linear .2s;
                border-radius: 3px;
                @media screen and (max-width: 575px) {
                    width: 90%;
                }
            }
            .username {
                margin-bottom: 20px;
            }
            .form-text {
                width: 70%;
                font-size: .74em;
                line-height: 20px;
                padding-left: 9px;
                margin-top: 4px;
                @media screen and (max-width: 575px) {
                    font-size: .65em;
                    width: 100%;
                }
            }
        }
    }
    .form-row-footer {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        @media screen and (max-width: 991px) {
            align-items: center;
          }
        .form-foot {
            width: 100%;
            margin-bottom: 20px;
            margin-left: 30%;
            @media screen and (max-width: 991px) {
              margin-left: unset;
              display: flex;
              justify-content: center;
              align-items: center;
              width: fit-content;
            }
            .form-link {
                display: inline-block;
                font-size: 15px;
                line-height: 20px;
                text-decoration: underline;
                color: #0878b5;
                img {
                    margin-right: 5px;
                }
            }
            .form-link-text {
                margin: 0 5px;
            }
        }
    }
}