.hero-container {
    display: flex;
    min-height: 80vh;
    flex-direction: column;
    @media screen and (max-width: 991px) {
        min-height: 55vh;
    }
    @media screen and (max-width: 575px) {
        min-height: unset;
    }
    .hero-title {
        text-align: left;
        margin-bottom: 2em;
    }
    p {
        margin-left: 2em;
    }
    .main-container {
        margin: 0;
        font-family: 'interstate';
        font-size: 15px;
        line-height: 20px;
        color: #525252;
        background-color: #ffffff;
        text-shadow: 0px 0px #dad8d8;
        .detail-header {
            background-color: #ebebeb;
            padding: 10px 15px;
            padding-bottom: 20px;
            .detail-body {
                font-size: 18px;
                font-weight: bold;
                margin: 20px 0 10px 0;
                .detail-info {
                    font-size: 16px;
                    font-weight: normal;
                    text-transform: uppercase;
                }
            }
        }
        .detail-data {
            .account-tabs {
                width: 100%;
                display: flex;
                position: relative;
                cursor: pointer;
                .tabs {
                    &.active {
                        border-bottom: solid 5px #0878b5;
                        p {
                            color: #0878b5 !important;
                            font-size: 18px;
                        }
                    }
                }
                .label-text {
                    margin-top: 30px;
                    .label-req {
                        color: #bc3232;
                        margin-right: 5px;
                    }
                }
                .tab-myaccount {
                    width: 50%;
                    text-align: center;
                    border-bottom: 1px solid #C0C0C0;
                    text-decoration: none;
                    .my-tab {
                        font-size: 18px;
                        font-weight: 300;
                        letter-spacing: -0.4px;
                        color: #525252;
                    }
                }:focus{
                    outline: none;
                }
                .tab-myaccount:hover, .tab-alternate:hover {
                    .my-tab {
                        color: #0878b5;
                        font-size: 18px;
                    }
                }
                .tab-alternate {
                    width: 50%;
                    text-align: center;
                    position: relative;
                    border-bottom: 1px solid #C0C0C0;
                    text-decoration: none;
                    .alt-tab {
                        font-size: 18px;
                        font-weight: 300;
                        letter-spacing: -0.4px;
                        color: #525252;
                    }
                }
            }
        }
    }
}
